import React from 'react';
import './EventPage.css';

const EventPage = () => {
    return(
        <div className="eventPageBlock">
            <div className="flyerImageBlock">
                <img 
                    src="PopUpPortalFlyer-Catan.png" 
                    className="flyerImage" 
                    alt='Free Board Gaming Event Flyer'
                />
            </div>
            <div className="eventInfoBlock">
                <h1 className="eventTitle">
                    Free Board Gaming Event
                </h1>
                <h3 className="eventLocation">
                    Wimberley Community Center
                </h3>
                <h3 className="eventDateTime">
                    October 12th, 2024 - open 12-6pm
                </h3>
                <p className="eventTimeNote">(Come anytime and stay as long as you want!)</p>
                <p className="eventDescription">
                    Step into Super Dimension&apos;s Pop-Up Portal and experience a variety of board games from our library for free! Bring your friends or come looking to meet new people.
                </p>
                <ul className="eventBulletPoints">
                    <li>Games Gurus available to teach & setup games for you.</li>
                    <li>Over 50 free games to try from our growing game library!</li>
                    <li>Open to all ages and experience levels.</li>
                    <li>Come anytime between 12-6pm and stay for as long as you want.</li>
                    <li>Free entry, free treats and free things to find!</li>
                </ul>
                <p className="eventDescription">
                    Lots of games available like:
                </p>
                <ul className="eventBulletPoints">
                    <li>Catan</li>
                    <li>Heat: Pedal to the Metal</li>
                    <li>Unmatched</li>
                    <li>Codenames</li>
                    <li>Betrayal at House on the Hill</li>
                    <li>Ticket to Ride</li>
                    <li>Wingspan</li>
                    <li>And so much more!</li>
                </ul>
            </div>
        </div>
    )
}

export default EventPage;
import ListingsPage from './pages/ListingsPage.tsx'
import AboutPage from './pages/AboutPage.tsx'
import EventPage from './pages/EventPage.tsx'
import HomePage from './pages/HomePage.tsx';
import TopNav from './features/TopNav.tsx';
import './css/grid.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<div>
          <TopNav />
          <HomePage />
          <EventPage />
        </div>} />
        <Route index element={<div>
          <TopNav />
          <HomePage />
          <EventPage />
        </div>} />
        <Route path="/Home" element={<div>
          <TopNav />
          <HomePage />
          <EventPage />
        </div>} />
        <Route path="/Events" element={<div>
          <TopNav />
          <EventPage />
        </div>} />
        <Route path="/About" element={<div>
          <TopNav />
          <AboutPage />
        </div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
import React from 'react';
import SocialMediaAction from '../features/SocialMediaAction.tsx';
import './HomePage.css';
import '../css/grid.css';

const HomePage = () => {

    return <div className="container home-page">
        <div className='row'>
            <div className='column-8'>
                <h1 className='greeting-text'>Welcome to Super Dimension Gaming in Wimberley!</h1>
            </div>
        </div>
        <div className='row gap-row'></div>
        <div className='row'>
            <div className='column-12'>
                <h2 className='action-text'>Follow us on social media for updates!</h2>
            </div>
        </div>
        <div className='row'>
            <div className="column-12">
                <SocialMediaAction />
            </div>
        </div>
    </div>
}

export default HomePage;
import React, { useState } from 'react';
import './TopNav.css';
import '../css/grid.css';
import { Link } from 'react-router-dom';

export enum Links {
    HOME = 'Home',
    EVENTS = 'Events',
    ABOUT = 'About Us'
}

// const screenWidth = window.innerWidth;
const screenWidth = window.matchMedia('(max-width: 800px)');

const TopNav = () => {
    const [openNav, setOpenNav] = useState(false);

    return <div className="container top-nav">
        {
            screenWidth.matches ? 
            <div className="row">
                {
                    openNav ? 
                        <div className='container'>
                            <nav className="column-6">
                                <div className="row">
                                    <div className="column-12">
                                        <Link className="column-1 nav-links nav-links-mobile" to="/">{Links.HOME}</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column-12">
                                        <Link className="column-1 nav-links nav-links-mobile" to="/Events">{Links.EVENTS}</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column-12">
                                        <Link className="column-1 nav-links nav-links-mobile" to="/About">{Links.ABOUT}</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div 
                                        className="column-12 mobile-nav-menu-block"
                                        onClick={() => setOpenNav(!openNav)}
                                    >
                                        <img 
                                            className="mobile-nav-menu-icon"
                                            src="./super-menu-icon.png"
                                            alt="Navigation menu icon."
                                        />
                                        <p className="mobile-nav-menu-text">Menu</p>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    :
                    <div className="container">
                        <div className="row">
                            <div 
                                className="column-12 mobile-nav-menu-block"
                                onClick={() => setOpenNav(!openNav)}
                            >
                                <img 
                                    className="mobile-nav-menu-icon"
                                    src="./super-menu-icon.png"
                                    alt="Navigation menu icon."
                                />
                                <p className="mobile-nav-menu-text">Menu</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        :
        <nav className="row">
            <Link className="column-1 nav-links" to="/Home">{Links.HOME}</Link>
            <Link className="column-1 nav-links" to="/Events">{Links.EVENTS}</Link>
            <Link className="column-1 nav-links" to="/About">{Links.ABOUT}</Link>
        </nav>
        }
        
    </div>
}

export default TopNav;